import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { CoursesComponent } from "./courses/courses.component";
import { PlacementsComponent } from "./placements/placements.component";
import { BlogsComponent } from "./blogs/blogs.component";
import { ContactComponent } from "./contact/contact.component";
import { DataAnalyticsComponent } from "./data-analytics/data-analytics.component";
import { BigdataComponent } from "./bigdata/bigdata.component";
import { FullstackComponent } from "./fullstack/fullstack.component";
import { ErpSalesComponent } from "./erp-sales/erp-sales.component";
import { ErpMaterialComponent } from "./erp-material/erp-material.component";
import { DatascienceComponent } from "./datascience/datascience.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { DevopsComponent } from "./devops/devops.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { UiUxComponent } from "./ui-ux/ui-ux.component";
import { PythonComponent } from "./python/python.component";
import { DaPyhtonComponent } from "./da-pyhton/da-pyhton.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { JptFullstackComponent } from './jpt-fullstack/jpt-fullstack.component';
import { JptDatascienceComponent } from './jpt-datascience/jpt-datascience.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AboutComponent,
    CoursesComponent,
    PlacementsComponent,
    BlogsComponent,
    ContactComponent,
    DataAnalyticsComponent,
    BigdataComponent,
    FullstackComponent,
    ErpSalesComponent,
    ErpMaterialComponent,
    DatascienceComponent,
    DevopsComponent,

    UiUxComponent,

    PythonComponent,

    DaPyhtonComponent,

    JptFullstackComponent,

    JptDatascienceComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}

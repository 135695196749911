import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-jpt-datascience",
  templateUrl: "./jpt-datascience.component.html",
  styleUrls: ["./jpt-datascience.component.css"]
})
export class JptDatascienceComponent implements OnInit {
  constructor() {}
  fullstack = [
    {
      title: "Track 1",
      info: "Data Analytics & Science Overview",
      items: [
        "Introduction to Analytics",
        "Data Analytics in Different Sectors",
        "Introduction to Data Science",
        "Data Science in Different Sectors",
        "Analytics Frameworks, "
      ]
    },
    {
      title: "Track 2",
      info: "Big Data Overview & Architecture ",
      items: [
        "Introduction to Big Data & Analytics",
        "Apache Hadoop and Hadoop Ecosystem",
        "Apache Hadoop file storage and cluster components",
        "Distributed Processing and Yarn Architecture"
      ]
    },
    {
      title: "Track 3",
      info: "Python Concepts",
      items: [
        "Introduction to Python Programming",
        " Environment setup,JupyterLab and Configurations",
        "Virtual Environments, Packages, and pip",
        "First Python Program",
        " Data types, variables, Input output operations",
        " Flow Control Statements, Functions and String"
      ]
    },
    {
      title: "Track 4",
      info: "Advanced Python Concepts",
      items: [
        "Regular Expression",
        "Sequences,dictionaries and sets",
        "Functions,String,Date and time",
        "Database Operations",
        "Testing and Debugging"
      ]
    },
    {
      title: "Track 5",
      info: "SQL Concepts       ",
      items: [
        "SQL Basics ",
        " Combining Data",
        " Aggregate Data ",
        " Complex SQL",
        " Advanced Functions"
      ]
    },
    {
      title: "Track 6",
      info: "Hands-On Learning: Python for Data Analytics & Science",
      items: [
        "Pandas, NumPy and SciPy",
        "ML and NLP with Scikit-Learn",
        "Data Visualization using matplotlib",
        "Learn to work with different file types",
        "APIs collect the data from internet"
      ]
    },
    {
      title: "Track 7",
      info: "AI, Machine & Deep Learning ",
      items: [
        "Introduction to AI and Machine learning",
        "Data Preprocessing",
        " Supervised and unsupervised algorithms",
        " Time series and social network analysis",
        " Ensemble learning with random forests and gradient boosting"
      ]
    },
    {
      title: "Track 8",
      info: "Tools and Language",
      items: [
        "Python Packages & Libraries ",
        "SQL and NoSQL Databases",
        " Data Visualizations Tableau,Power BI",
        "Jupyter notebook, Spyder & Anaconda  "
      ]
    },
    {
      title: "Track 9",
      info: "Data Analytics &  Science in Different Sector      ",
      items: [
        "Marketing & Retail",
        "Web & Social Media",
        "Finance & Risk",
        " Supply Chain & Logistics "
      ]
    },
    {
      title: "Track 10",
      info: "Capstone Project",
      items: [
        " Retail Store analytics",
        " Mask Detection ",
        " Spend Analytics",
        " Fraud Detection",
        " Customer Segmentation",
        " AI Based Traffic Challan System"
      ]
    },
    {
      title: "Track 11",
      info: "Career Service",
      items: [
        "Career Advice",
        "Create a high-quality resume and cover letter",
        "Interview coaching and practice",
        "Job search Advice",
        "Mock interviews for both technical and non-technical topics"
      ]
    },
    {
      title: "Track 12",
      info: "Mentorship Support",
      items: [
        "Setting learning goals",
        "Review of projects and exercises",
        "Industry insights",
        "Interview tips",
        "Career and Job Search advice",
        "Tracking weekly progress"
      ]
    }
  ];
  ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bigdata',
  templateUrl: './bigdata.component.html',
  styleUrls: ['./bigdata.component.css']
})
export class BigdataComponent implements OnInit {



  bigdata = [{
    title: "Track 1",
    info: "Big Data Overview ",
    items: ["Introduction to Big Data",
      "Apache Hadoop and Hadoop Ecosystem",
      "Apache Hadoop file storage and cluster components",
      "Distributed Processing and Yarn Architecture",
      // "Apache Spark Framework,Spark Shell,RDD, SparkSQl",
      // "Introduction of Scala, Working with Datasets "
      ]
  },
  {
    title: "Track 2",
    info: "Data Ingest",
    items: ["Data Ingestion using Sqoop",
    "Ingest real-time and near-real-time streaming data into HDFS",
    "Process streaming data as it is loaded onto the cluster",
    "Load data into HDFS using the Hadoop File System commands"
    ]
  },
  {
    title: "Track 3",
    info: "Transform,Stage and Store",
    items: ["Load RDD data from HDFS for use in Spark applications",
    "Write the results from an RDD back into HDFS using Spark",
    "Read and write files in a variety of file formats",
    "Perform standard extract, transform, load (ETL) processes on data"
    ]
  },
  {
    title: "Track 4",
    info: "Data Analysis",
    items: ["Use metastore tables as an input source or an output sink for Spark applications",
    "Understand the fundamentals of querying datasets in Spark",
    "Filter data and Write queries that calculate aggregate statistics",
    "Join disparate datasets using Spark",
    "Produce ranked or sorted data"]
  },
  {
    title: "Track 5",
    info: "Tools and Language",
    items: ["Sqoop",
    "Hbase",
    "Hive",
    "Scala and Python",
    "Kafka"
    ]
  },
  {
    title: "Track 6",
    info: "Capstone Project",
    items: [" Crime Prediction",
    "Fraud Detection",
    "Modelling Natural Language"]
  }, {
    title: "Track 7",
    info: "Career Service",
    items:["Career Advice",
    "Create a high-quality resume and cover letter",
    "Interview coaching and practice",
    "Job search Advice",
    "Mock interviews for both technical and non-technical topics",]
  },
  {
    title: "Track 8",
    info: "Mentorship Support",
    items:["Setting learning goals" ,
    "Review of projects and exercises",
    "Industry insights",
    "Interview tips",
    "Career and Job Search advice",
    "Tracking weekly progress"]
  }

  ];

  constructor() { }

  ngOnInit() {
  }

}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-da-pyhton",
  templateUrl: "./da-pyhton.component.html",
  styleUrls: ["./da-pyhton.component.css"]
})
export class DaPyhtonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  dataanalytics = [
    {
      title: "Track 1",
      info: "Data Analytics with Python Introduction ",
      items: [
        " Introduction to Python Programming",
        "Python Features and Libraries ",
        "Introduction to Data Analytics",
        "Type of data analytics",
        "Data Analytics in Different Sectors"
      ]
    },
    {
      title: "Track 2",
      info: "Development Environments",
      items: [
        " Environment setup and Configurations",
        " JupyterLab",
        " Virtual Environments, Packages, and pip",
        " Source Control GIT",
        "SQL and NoSQL Databases"
      ]
    },
    {
      title: "Track 3",
      info: "Python Concepts",
      items: [
        " Data types, variables, Input output operations",
        "Flow Control Statements, Functions and String",
        "Iterables: sequences,Dictionaries and Sets",
        "Modules and Package"
      ]
    },
    {
      title: "Track 4",
      info: "Data Analytics using Python",
      items: [
        "Numpy Multidimensional Arrays  ",
        "Pandas for data analysis",
        "Scipy Data processing",
        "Data Visualization using matplotlib"
      ]
    },
    {
      title: "Track 5",
      info: "Hands-On Learning",
      items: [
        "Learn to use Pandas, NumPy and SciPy",
        "Data Visualization using matplotlib",
        "Learn to work with different file types",
        "APIs collect the data from internet"
      ]
    },
    {
      title: "Track 6",
      info: "Capstone Project",
      items: ["Spend Analytics", "Retail Store Insight", "Risk Analytics"]
    },
    {
      title: "Track 7",
      info: "Career Service",
      items: [
        "Career Advice",
        "Create a high-quality resume and cover letter",
        "Interview coaching and practice",
        "Job search Advice",
        "Mock interviews for both technical and non-technical topics"
      ]
    },
    {
      title: "Track 8",
      info: "Mentorship Support",
      items: [
        "Setting learning goals",
        "Review of projects and exercises",
        "Industry insights",
        "Interview tips",
        "Career and Job Search advice",
        "Tracking weekly progress"
      ]
    }
  ];
}

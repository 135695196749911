import { Component, OnInit } from "@angular/core";
import { Router, Route } from "@angular/router";
import { PwaService } from "../pwa.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ViewportScroller } from "@angular/common";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {
  formspree = "https://formspree.io/mjvadvbw";
  data = [
    {
      id: "1",
      title: "Data Analytics",
      info:
        "Learn the latest in data wrangling, data mining, data modelling and data visualization and apply it to solve real-life projects to enhance your career."
    },
    {
      id: "2",
      title: "Big Data ",
      info:
        "Learn the latest Big Data Tools for Data Ingestion, Transform,Stage, Store and Data visualization and apply it solve real-life project."
    },
    {
      id: "3",
      title: "Full Stack Dev",
      info:
        "Master the frontend application development along with backend technologies,with deployment tools and techniques. "
    },
    {
      id: "4",
      title: "Data Science",
      info:
        "Learners will develop foundational Data Science skills to prepare them for a career or further learning that involves more advanced topics in Data Science "
    },

    {
      id: "9",
      title: "Data Analytics - Python",
      info:
        "The course assumes that you already have had a good amount of Python training and it will start by teaching you how to use Jupyter Notebook"
    },

    {
      id: "10",
      title: "Python",
      info:
        "The course is aimed at students new to the language who may or may not have experience with other programming languages"
    },
    {
      id: "5",
      title: "ERP - MM",
      info:
        "Course will prepare you to define a customizing project and configure general settings, organizational units, and master data. "
    },
    {
      id: "6",
      title: "ERP - SD",
      info:
        "Course is ideal for persons requiring detailed knowledge about customizing of sales and delivery processes as well as basic functions."
    },
    {
      id: "7",
      title: "Devops",
      info:
        "Course is ideal for persons requiring detailed knowledge about learning devops techniques and tools."
    },
    {
      id: "8",
      title: "UI/UX",
      info:
        "Course is ideal for persons requiring detailed knowledge about user interface and user experience."
    }
  ];
  jptData = [
    {
      id: "11",
      title: "Full Stack Development (JPT)",
      info:
        "Master the frontend application development along with backend technologies,with deployment tools and techniques."
    },
    {
      id: "12",
      title: "Big Data Analytics & Science (JPT)",
      info:
        "Learners will develop foundational Data Science skills to prepare them for a career or further learning that involves more advanced topics in Data Science "
    }
  ];
  form: FormGroup;
  constructor(
    private router: Router,
    public Pwa: PwaService,
    private viewportScroller: ViewportScroller,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    public fb: FormBuilder
  ) {
    this.form = this.fb.group({
      name: "",
      email: "",
      services: "",
      message: ""
    });
  }

  questions = [
    {
      ques: "What will the timings for training  ?",
      ans:
        "Mode of training is Online only, timings are morning/ evening 7-9 am/pm."
    },
    {
      ques: "What will be the  training duration ?",
      ans: "It will be for 60 hours."
    },
    {
      ques: "How will the students be trained  ?      ",
      ans:
        "It will be done by industry experts who have more than 5 years experience."
    },
    {
      ques: "What will be the fees for training?      ",
      ans:
        "Fee is fix, for the convenience of the trainee , it may be paid  in two installments(60&40).        "
    },
    {
      ques: "What is the mode of training?      ",
      ans: "Mode of training is Online only"
    },
    {
      ques: "If we take video training will I get complete subject knowledge?",
      ans:
        "Our video program is a detailed program with similar content of classroom program. We assure you that every topic is covered in our syllabus with detailed theory and practical examples."
    },
    {
      ques: "How to do practice in Video Program?",
      ans:
        "In each topic you will get assignment tasks given by faculty and you must submit the assignments by mail. We also provide live access to our server to practice on WordPress and implement the concepts in practical manner."
    },
    {
      ques: "Will I get certificate or not? ",
      ans: "We provide a proper certificate after completion of the course. "
    },
    {
      ques: "Do you provide Live Project with Training?      ",
      ans:
        "Yes, after the completion of the course. We will provide opportunity to work on our live projects."
    },
    {
      ques: " Who going to be clarifying our doubts?      ",
      ans:
        "You can ask any questions to our faculty by whatsapp/email. He will be responding to all your queries"
    }
  ];

  ngOnInit() {}

  navigate(id) {
    if (id == 1) this.router.navigate(["data"]);
    if (id == 2) this.router.navigate(["bigdata"]);
    if (id == 3) this.router.navigate(["fullstack"]);
    if (id == 4) this.router.navigate(["datascience"]);
    if (id == 5) this.router.navigate(["erpmm"]);
    if (id == 6) this.router.navigate(["erpsd"]);
    if (id == 7) this.router.navigate(["devops"]);
    if (id == 8) this.router.navigate(["ui-ux"]);
    if (id == 9) this.router.navigate(["data-analytics-python"]);
    if (id == 10) this.router.navigate(["python"]);
    if (id == 11) this.router.navigate(["jpt-fullstack"]);
    if (id == 12) this.router.navigate(["jpt-datascience"]);
  }
  installPwa(): void {
    this.Pwa.promptEvent.prompt();
  }
  sendForm() {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append("Accept", "application/json");

    this.http
      .post(this.formspree, this.form.value, { headers: headers })
      .subscribe(
        response => {
          console.log(response);
          this.openSnackBar("Thanku For Submitting", "");
          this.form.reset();
          (<HTMLInputElement>document.getElementById("email")).value = "";
          this.viewportScroller.scrollToAnchor("top");
        },
        error => console.log(error)
      );
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000
    });
  }
}

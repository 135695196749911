import { Component } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { SeoserviceService } from "./seoservice.service";
import { map, filter, mergeMap } from "rxjs/operators";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "datasync";

  constructor(
    private router: Router,
    private _seoService: SeoserviceService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this._seoService.updateTitle(event["title"]);
        this._seoService.updateOgUrl(event["keywords"]);
        //Updating Description tag dynamically with title
        this._seoService.updateDescription(
          event["title"] + event["description"]
        );
      });
  }
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-devops",
  templateUrl: "./devops.component.html",
  styleUrls: ["./devops.component.css"]
})
export class DevopsComponent implements OnInit {
  devops = [
    {
      title: "Track 1",
      info: "DevOps Overview",
      items: [
        "Introduction to DevOps",
        "Devops Lifecycle and Tools",
        "Devops Pipeline ",
        "Devops Ecosystems ",
        "Introduction to DevOps on Cloud"
      ]
    },
    {
      title: "Track 2",
      info: "Version Control with Git",
      items: [
        "Introduction to Version Control",
        "Install Git & common commands ",
        "Working with Remote Repositories",
        "Branching, Merging, Stashing, Rebasing, Reverting",
        "Git workflows & cheat sheet"
      ]
    },
    {
      title: "Track 3",
      info: "Continuous Integration using Jenkins ",
      items: [
        "Introduction to CI & Jenkins",
        "Jenkins Management",
        "Adding a slave node to Jenkins",
        "Building Delivery Pipeline",
        "Create a pipeline script to deploy an application"
      ]
    },
    {
      title: "Track 4",
      info: "Continuous Deployment: Containerization with Docker ",
      items: [
        "Introduction to Docker & Container",
        "Understanding images and containers",
        "Working with containers",
        "Publishing Image on Docker Hub",
        "Docker Container Networking"
      ]
    },
    {
      title: "Track 5",
      info: "Configuration Management with Ansible",
      items: [
        "Introduction to Ansible ",
        "Ansible Installation & Configuring Ansible Roles",
        "Puppet Architecture,Manifests and Modules",
        "Write Playbooks & Execute ad hoc commands"
      ]
    },
    {
      title: "Track 6",
      info: "Containerization using Kubernetes",
      items: [
        " Kubernetes Cluster Architecture",
        "Spinning up a Kubernetes Cluster on Ubuntu VMs",
        "Creating a Deployment in Kubernetes using YAML",
        "Containers and Container Orchestration",
        "Deploying an App using Dashboard"
      ]
    },
    {
      title: "Track 7",
      info: "Career Service",
      items: [
        "Career Advice",
        "Create a high-quality resume and cover letter",
        "Interview coaching and practice",
        "Job search Advice",
        "Mock interviews for both technical and non-technical topics"
      ]
    },
    {
      title: "Track 8",
      info: "Mentorship Support",
      items: [
        "Setting learning goals",
        "Review of projects and exercises",
        "Industry insights",
        "Interview tips",
        "Career and Job Search advice",
        "Tracking weekly progress"
      ]
    }
  ];

  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-python",
  templateUrl: "./python.component.html",
  styleUrls: ["./python.component.css"]
})
export class PythonComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
  python = [
    {
      title: "Track 1",
      info: "Python Introduction",
      items: [
        "Introduction to Python Programming",
        "History of python",
        "Python Features and Versions ",
        " Uses of Python",
        "First Python Program"
      ]
    },
    {
      title: "Track 2",
      info: "Development Environments ",
      items: [
        " Environment setup and Configurations",
        " JupyterLab",
        " Virtual Environments, Packages, and pip",
        " Source Control GIT",
        "SQL and NoSQL Databases"
      ]
    },
    {
      title: "Track 3",
      info: "Python Concepts",
      items: [
        "Data types, variables, Input output operations",
        " Flow Control Statements, Functions and String",
        " Iterables: sequences,Dictionaries and Sets",
        " Modules and Package"
      ]
    },
    {
      title: "Track 4",
      info: "Advanced Python Concepts",
      items: [
        "Regular Expressions",
        "Working with Files",
        "Database Handling",
        "Testing and Debugging",
        "Classes and Object"
      ]
    },
    {
      title: "Track 5",
      info: "Hands-On Learning",
      items: [
        " Learn to use sequences,dictionaries and sets",
        " Functions,String,Date and time ",
        " Database Operations ",
        " File Generation as PDF and Excel"
      ]
    },
    {
      title: "Track 6",
      info: "Capstone Project",
      items: [
        "Web Application with Flask ",
        "Master and Relationship Data Management ",
        "Reports Generations with Email Notifications",
        "User Management system"
      ]
    },
    {
      title: "Track 7",
      info: "Career Service",
      items: [
        "Career Advice",
        "Create a high-quality resume and cover letter",
        "Interview coaching and practice",
        "Job search Advice",
        "Mock interviews for both technical and non-technical topics"
      ]
    },
    {
      title: "Track 8",
      info: "Mentorship Support",
      items: [
        "Setting learning goals",
        "Review of projects and exercises",
        "Industry insights",
        "Interview tips",
        "Career and Job Search advice",
        "Tracking weekly progress"
      ]
    }
  ];
}

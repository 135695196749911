import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-ui-ux",
  templateUrl: "./ui-ux.component.html",
  styleUrls: ["./ui-ux.component.css"]
})
export class UiUxComponent implements OnInit {
  ui = [
    {
      title: "Track 1",
      info: "Principles of Design",
      items: [
        " UI vs. UX Design",
        "Core Stages of Design Thinking",
        "Divergent and Convergent Thinking",
        "Brainstorming and Gamestorming",
        "Observational Empathy"
      ]
    },
    {
      title: "Track 2",
      info: "UI Design Foundations ",
      items: [
        "Visual and UI Principles",
        "UI Elements and Patterns",
        "Interaction Behaviors and Principles",
        "Understanding Brand Platforms",
        "Style Guides"
      ]
    },
    {
      title: "Track 3",
      info: "Sketching and Wireframing",
      items: [
        "Sketching Principles",
        "Sketching Red Routes",
        "Usability Testing",
        "Responsive Design",
        "Wireframing & Wireflows"
      ]
    },
    {
      title: "Track 4",
      info: "High Fidelity Prototyping ",
      items: [
        "Inclusive Design and Designing for Accessibility",
        "Building High-Fidelity Mockups",
        "Designing Efficiently with Tools",
        "Interaction Patterns",
        "Design animations and interactions "
      ]
    },
    {
      title: "Track 5",
      info: "Prototyping & Testing",
      items: [
        "Building a Prototype",
        "Conducting Usability Tests",
        "Other Evaluative User Research Methods",
        "Synthesizing Test Findings",
        "Prototype Iteration"
      ]
    },
    {
      title: "Track 6",
      info: "Capstone Project",
      items: [
        " Identifying Requirements and Writing Problem Statements",
        "Solution Ideation & Information Architecture",
        "Creating Personas &  User Stories",
        "Usability Testing"
      ]
    },
    {
      title: "Track 7",
      info: "Career Service",
      items: [
        "Career Advice",
        "Create a high-quality resume and cover letter",
        "Interview coaching and practice",
        "Job search Advice",
        "Mock interviews for both technical and non-technical topics"
      ]
    },
    {
      title: "Track 8",
      info: "Mentorship Support",
      items: [
        "Setting learning goals",
        "Review of projects and exercises",
        "Industry insights",
        "Interview tips",
        "Career and Job Search advice",
        "Tracking weekly progress"
      ]
    }
  ];

  constructor() {}

  ngOnInit() {}
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-analytics',
  templateUrl: './data-analytics.component.html',
  styleUrls: ['./data-analytics.component.css']
})
export class DataAnalyticsComponent implements OnInit {

  dataanalytics = [{
    title: "Track 1",
    info: "Data Analytics Overview ",
    items: ["Introduction to Analytics",
      "Type of data analytics",
      "Data Analytics Benefits",
      "Data Visualization for decision making"]
  },
  {
    title: "Track 2",
    info: "Analytics Framework",
    items: ["Introduction",
      "Data Understanding",
      "Data Preparation",
      "Modelling ",
      "Reporting"
    ]
  },
  {
    title: "Track 3",
    info: "Data Analytics in Different Sector",
    items: ["Marketing & Retail",
      "Web & Social Media",
      "Finance & Risk",
      "Supply Chain & Logistics"
    ]
  },
  {
    title: "Track 4",
    info: "Tools and Language ",
    items: ["SQL",
      "Tableau",
      "Zoomdata",
      "Superset"]
  },
  {
    title: "Track 5",
    info: "Hands-On Learning",
    items: ["Case Studies ", "Economics case studies", "Statistics case studies", "Assignments"]
  },
  {
    title: "Track 6",
    info: "Capstone Project",
    items: ["Retail Store data analytics",
      "Spend Analytics",
      "Risk Analytics"]
  }, {
    title: "Track 7",
    info: "Career Service",
    items:["Career Advice",
    "Create a high-quality resume and cover letter",
    "Interview coaching and practice",
    "Job search Advice",
    "Mock interviews for both technical and non-technical topics",]
  },
  {
    title: "Track 8",
    info: "Mentorship Support",
    items:["Setting learning goals" ,
    "Review of projects and exercises",
    "Industry insights",
    "Interview tips",
    "Career and Job Search advice",
    "Tracking weekly progress"]
  }

  ];

  constructor() { }

  ngOnInit() {
  }

}

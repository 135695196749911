import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-erp-sales",
  templateUrl: "./erp-sales.component.html",
  styleUrls: ["./erp-sales.component.css"]
})
export class ErpSalesComponent implements OnInit {
  erpsales = [
    {
      title: "Track 1",
      info: " Introduction to ERP, SAP and Methodology ",
      items: [
        "Introduction to ERP",
        "Introduction to SAP, S/4 Hana and FIORI",
        "Agile Methodology",
        "Technical Objects Overview "
      ]
    },
    {
      title: "Track 2",
      info: " Enterprise Structure Overview",
      items: [
        "ENTERPRISE STRUCTURE & Management ",
        "Organization Structure",
        "MASTER DATA",
        "Basic Functions"
      ]
    },
    {
      title: "Track 3",
      info: "Sales and Distribution Process ",
      items: [
        "Sales Process and Document",
        "Shipping & Picking",
        "Billing Process and Customizing",
        "PRICING and Condition technique",
        "SHIPPING Process and Customization"
      ]
    },
    {
      title: "Track 4",
      info: " Business Process & Determination",
      items: [
        "Credit Management",
        "Availability Check",
        "Third Party Sales",
        "Inter Company Sales",
        "Stock Transfer Order"
      ]
    },
    {
      title: "Track 5",
      info: " Special Sales and Complaints",
      items: [
        "Special Document",
        "Complaints",
        "Outline Agreement",
        "Consignment",
        "Integration with other Modules"
      ]
    },
    {
      title: "Track 6",
      info: "Capstone Project",
      items: [
        "Business Blueprint",
        "Implementation Process",
        "Support Process",
        "Functional Design Documents "
      ]
    },
    {
      title: "Track 7",
      info: "Career Service",
      items: [
        "Career Advice",
        "Create a high-quality resume and cover letter",
        "Interview coaching and practice",
        "Job search Advice",
        "Mock interviews for both technical and non-technical topics"
      ]
    },
    {
      title: "Track 8",
      info: "Mentorship Support",
      items: [
        "Setting learning goals",
        "Review of projects and exercises",
        "Industry insights",
        "Interview tips",
        "Career and Job Search advice",
        "Tracking weekly progress"
      ]
    }
  ];

  constructor() {}

  ngOnInit() {}
}

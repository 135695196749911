import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-datascience",
  templateUrl: "./datascience.component.html",
  styleUrls: ["./datascience.component.css"]
})
export class DatascienceComponent implements OnInit {
  datascience = [
    {
      title: "Track 1",
      info: "Data Science Overview ",
      items: [
        "Introduction to Data Science",
        "Data Science in Different Sectors",
        "Python components and its Standard Libraries",
        "Introduction to Big Data and other Tools"
      ]
    },
    {
      title: "Track 2",
      info: "Statistics and Business Applications",
      items: [
        "Introduction to Statistics",
        "Descriptive and Inferential Statistics",
        "Data Sampling and Data Distributions",
        "Hypothesis Testing"
      ]
    },
    {
      title: "Track 3",
      info: "Machine Learning ",
      items: [
        "Introduction to AI and Machine learning",
        "Data Preprocessing",
        "Supervised and unsupervised algorithms",
        "Time series and social network analysis"
      ]
    },
    {
      title: "Track 4",
      info: "Tools and Language ",
      items: [
        "Data Science with R and Python",
        "Data Visualizations Tools",
        "SQL and NoSQL Databases ",
        "Integration with Hadoop Mapreduce and Spark"
      ]
    },
    {
      title: "Track 5",
      info: "Hands-On Learning",
      items: [
        "Learn to use Pandas, NumPy and SciPy",
        "ML and NLP with Scikit-Learn",
        "Data Visualization using matplotlib",
        "Learn to work with different file types",
        "APIs collect the data from internet"
      ]
    },
    {
      title: "Track 6",
      info: "Capstone Project",
      items: [
        " Property Price Prediction",
        "Customer Segmentation",
        "Loan Prediction Model",
        "Retail Store Insights ",
        "Fraud Detection "
      ]
    },
    {
      title: "Track 7",
      info: "Career Service",
      items: [
        "Career Advice",
        "Create a high-quality resume and cover letter",
        "Interview coaching and practice",
        "Job search Advice",
        "Mock interviews for both technical and non-technical topics"
      ]
    },
    {
      title: "Track 8",
      info: "Mentorship Support",
      items: [
        "Setting learning goals",
        "Review of projects and exercises",
        "Industry insights",
        "Interview tips",
        "Career and Job Search advice",
        "Tracking weekly progress"
      ]
    }
  ];

  constructor() {}

  ngOnInit() {}
}

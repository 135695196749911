import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { BlogsComponent } from "./blogs/blogs.component";
import { ContactComponent } from "./contact/contact.component";
import { CoursesComponent } from "./courses/courses.component";
import { PlacementsComponent } from "./placements/placements.component";
import { DataAnalyticsComponent } from "./data-analytics/data-analytics.component";
import { BigdataComponent } from "./bigdata/bigdata.component";
import { FullstackComponent } from "./fullstack/fullstack.component";
import { DatascienceComponent } from "./datascience/datascience.component";
import { ErpMaterialComponent } from "./erp-material/erp-material.component";
import { DevopsComponent } from "./devops/devops.component";
import { UiUxComponent } from "./ui-ux/ui-ux.component";
import { DaPyhtonComponent } from "./da-pyhton/da-pyhton.component";
import { PythonComponent } from "./python/python.component";
import { JptFullstackComponent } from "./jpt-fullstack/jpt-fullstack.component";
import { JptDatascienceComponent } from "./jpt-datascience/jpt-datascience.component";

const routes: Routes = [
  { path: "home", redirectTo: "/", pathMatch: "full" },
  {
    path: "",
    component: HomeComponent,
    data: {
      title:
        "Data Science Courses ONLINE: Training for Graduates & Post-Graduates",
      description:
        "Datasync offers ONLINE courses on Data Science, big data, full stack web development & data analytics for Graduates & Post-Graduates in Bhopal & Mumbai.  ",
      keywords:
        "Datasync, Full Stack Web Development Courses, Full Stack Web Development, learn big data, big data course, data analytics courses, job oriented courses in Bhopal, corporate training courses "
    }
  },
  {
    path: "about",
    component: AboutComponent,
    data: {
      title:
        "Big Data Courses Learn Online - Datasync , Full Stack Development, Data Analytics",
      description:
        "Datasync offers Job Oriented, Corporate Training for Graduates & Post-Graduates in Bhopal and Mumbai. Join online training for big data, data analytics, full stack web development etc. ",
      keywords:
        "Big Data Courses, learn Big Data, Big Data Certification, Big Data online Courses, Big Data Courses in Bhopal and Mumbai "
    }
  },
  { path: "blogs", component: BlogsComponent },
  {
    path: "contact",
    component: ContactComponent,
    data: {
      title: "Datasync - Big Data and Business Analytics",
      description:
        "Datasync offers Big Data and Business Analytics courses for those who want to become the JOB ready. Join our online and class room programs today!",
      keywords: "https://datasync.co.in/ "
    }
  },
  {
    path: "courses",
    component: CoursesComponent,
    data: {
      title:
        "Full Stack Web Development Courses, Big Data Courses , Data Analytics Courses",
      description:
        "– Join corporate training courses including Full Stack Web Development Courses, Big Data Courses & data analytics courses at Datasync ",
      keywords:
        "Business Analytics, Business Analytics course, Big Data, learn Big Data, Big Data online training"
    }
  },
  {
    path: "placements",
    component: PlacementsComponent,
    data: {
      title:
        "Job Oriented Courses after BSC Computer Science | Corporate Training",
      description:
        "Here are top Job Oriented Courses after BSC Computer Science. Join our corporate training online, classroom training in Bhopal and Mumbai and become JOB ready. ",
      keywords:
        "Datasync, Full Stack Web Development Courses, Full Stack Web Development, learn big data, big data course, data analytics courses "
    }
  },
  {
    path: "data",
    component: DataAnalyticsComponent,
    data: {
      title: "Data Analysis Courses in Bhopal  | Learn Online Data Analysis",
      description:
        "Beginner Data Analysis courses in Bhopal from Datasync. We provide online courses for mastering in big data and business analytics.  ",
      keywords:
        "Data Analysis, Data Analysis course in Bhopal, learn Data Analysis, Data Analysis course"
    }
  },
  {
    path: "bigdata",
    component: BigdataComponent,
    data: {
      title: "Big Data Courses in Bhopal | Big Data and Business Analytics",
      description:
        "Big Data courses online in Bhopal at Datasync. Learn Big Data analytics tools and technologies today. Learn/ advance your engineering or data science career. ",
      keywords:
        " Big Data Courses in Bhopal, Big Data and Business Analytics, Big Data, learn Big Data"
    }
  },
  {
    path: "fullstack",
    component: FullstackComponent,
    data: {
      title:
        "Full Stack Web Development Courses  in Bhopal | Learn Full Stack Online",
      description:
        " Full Stack Web Development Courses in Bhopal will enable you to build interactive and responsive web applications for your clients. ",
      keywords:
        "Full Stack Web Development Courses in Bhopal, Learn Full Stack Online, Learn Full Stack development Online "
    }
  },
  {
    path: "datascience",
    component: DatascienceComponent,
    data: {
      title:
        "Data Science Online Courses in Bhopal | Learn Data Science Online",
      description:
        "Join Data Science Online Courses in Bhopal at Datasync. We provide advanced & affordable Data Science course for professionals.",
      keywords:
        "Data Science Online Courses in Bhopal, Data Science Courses, learn Data Science "
    }
  },
  {
    path: "erpmm",
    component: ErpMaterialComponent,
    data: {
      title: "ERP - Online Courses in Bhopal , Classes, Training at Datasync",
      description:
        " ERP - Online Courses in Bhopal , Learn how to use specific ERP software’s like SAP ERP, or get simple broad introduction to the concepts that underpin ERP. ",
      keywords:
        " ERP, ERP Online Courses in Bhopal , ERP Classes, ERP Training, Datasync "
    }
  },
  {
    path: "erpsd",
    component: ErpMaterialComponent,
    data: {
      title:
        "Online ERP Course in Bhopal , SAP and Methodology – Inventory Management",
      description:
        " Learn ERP in Bhopal and Learn ERP such as SAP and other at Datasync. Learn SAP and it Methodology, Inventory Management, Special Stocks and Procurement types etc. ",
      keywords:
        "ERP, ERP Online Courses in Bhopal , ERP Classes, ERP Training, Datasync, Inventory Management"
    }
  },
  {
    path: "data-analysis-course-in-mumbai",
    component: DataAnalyticsComponent,
    data: {
      title: "Data Analysis Courses in Mumbai | Learn Online Data Analysis",
      description:
        "Beginner Data Analysis courses in Mumbai from Datasync. We provide online courses for mastering in big data and business analytics.  ",
      keywords:
        "Data Analysis, Data Analysis course, learn Data Analysis, Data Analysis course in Mumbai"
    }
  },
  {
    path: "data-analytics-python",
    component: DaPyhtonComponent,
    data: {
      title: "Data Analysis Courses in Mumbai | Learn Online Data Analysis",
      description:
        "Beginner Data Analysis courses in Mumbai from Datasync. We provide online courses for mastering in big data and business analytics.  ",
      keywords:
        "Data Analysis, Data Analysis course, learn Data Analysis, Data Analysis course in Mumbai"
    }
  },
  {
    path: "python",
    component: PythonComponent,
    data: {
      title: "Python Courses in Mumbai | Learn Online Data Analysis",
      description:
        "Beginner python courses in Mumbai from Datasync. We provide online courses for mastering in big data and business analytics.  ",
      keywords: "python, python course, learn python, python course in Mumbai"
    }
  },

  {
    path: "big-data-course-in-mumbai",
    component: BigdataComponent,
    data: {
      title: "Big Data Courses in Mumbai | Big Data and Business Analytics",
      description:
        "Big Data courses online in Mumbai at Datasync. Learn Big Data analytics tools and technologies today. Learn/ advance your engineering or data science career. ",
      keywords:
        " Big Data Courses in Mumbai, Big Data and Business Analytics, Big Data, learn Big Data"
    }
  },
  {
    path: "full-stack-course-in-mumbai",
    component: FullstackComponent,
    data: {
      title:
        "Full Stack Web Development Courses | Learn Full Stack Online | Mumbai",
      description:
        " Join us for Full Stack Developer Course in Mumbai. Become a master at coding with our Full Stack Developer Course in Mumbai and get 100% placement assistance. ",
      keywords:
        "Full Stack Web Development Courses, Learn Full Stack Online, Learn Full Stack development Online "
    }
  },
  {
    path: "jpt-fullstack",
    component: JptFullstackComponent,
    data: {
      title:
        "Full Stack Web Development Courses | Learn Full Stack Online | Mumbai",
      description:
        " Join us for Full Stack Developer Course in Mumbai. Become a master at coding with our Full Stack Developer Course in Mumbai and get 100% placement assistance. ",
      keywords:
        "Full Stack Web Development Courses, Learn Full Stack Online, Learn Full Stack development Online "
    }
  },
  {
    path: "data-science-course-in-mumbai",
    component: DatascienceComponent,
    data: {
      title:
        "Data Science Online Courses in Mumbai | Learn Data Science Online",
      description:
        "Join Data Science Course in Mumbai and get your dream job today! We are the best Data Science Training Institute in Mumbai.",
      keywords:
        "Data Science Online Courses in Mumbai, Data Science Courses, learn Data Science "
    }
  },
  {
    path: "jpt-datascience",
    component: JptDatascienceComponent,
    data: {
      title:
        "Data Science Online Courses in Mumbai | Learn Data Science Online",
      description:
        "Join Data Science Course in Mumbai and get your dream job today! We are the best Data Science Training Institute in Mumbai.",
      keywords:
        "Data Science Online Courses in Mumbai, Data Science Courses, learn Data Science "
    }
  },
  {
    path: "ERP-Material-course-in-mumbai",
    component: ErpMaterialComponent,
    data: {
      title: " ERP Course in Mumbai, SAP ERP Training in Mumbai",
      description:
        "Best SAP ERP training in Mumbai. Enroll with top institute for SAP ERP courses, classes in Mumbai with ✓Real-Time Project Training. ",
      keywords:
        " SAP ERP Training in Mumbai, ERP Certification Course, ERP Course in Mumbai      "
    }
  },
  {
    path: "ERP-Material-course-in-mumbai",
    component: ErpMaterialComponent,
    data: {
      title: "SAP Material Management Consultant Training in Mumbai ",
      description:
        "Learn how to get up and running with SAP Material Management (MM). This course is an ERP solution that ensures organized and structured process associated with materials management.        ",
      keywords:
        "SAP Material Management, SAP ERP course, SAP ERP module training  "
    }
  },
  {
    path: "devops",
    component: DevopsComponent
  },
  {
    path: "ui-ux",
    component: UiUxComponent
  },
  { path: "**", redirectTo: "" }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-jpt-fullstack",
  templateUrl: "./jpt-fullstack.component.html",
  styleUrls: ["./jpt-fullstack.component.css"]
})
export class JptFullstackComponent implements OnInit {
  fullstack = [
    {
      title: "Track 1",
      info: "Angular Overview ",
      items: [
        "Introduction to Angular",
        "The concepts of typescript",
        "Create components manually and decompose Monolithic UI",
        "Modularize angular application",
        "Learn the power of dependency injection & routing"
        // "Apache Spark Framework,Spark Shell,RDD, SparkSQl",
        // "Introduction of Scala, Working with Datasets "
      ]
    },
    {
      title: "Track 2",
      info: "Overview Of Node Js",
      items: [
        "Understand how to write asynchronous code ",
        "Learn how to install, update and uninstall node packages using npm",
        "Understand how to develop web applications and apis",
        "Learn how to secure web applications and apis using JWT "
      ]
    },
    {
      title: "Track 3",
      info: "Basics of Mysql",
      items: [
        "Basic concepts of RDBMS",
        "What is SQL? Where is it used? Types ofSQL",
        "Advantages of RDBMS"
      ]
    },
    {
      title: "Track 4",
      info: "Basics of Mongodb",
      items: [
        "Basic concepts of databases",
        "What is NoSQL? Where is it used? Types of NoSQL",
        "Advantages of NoSQL over RDBMS",
        "Introduction to JSON/BSON and its data types"
      ]
    },
    {
      title: "Track 5",
      info: "Devops",
      items: [
        "LINUX Basics:  Unix and linux",
        "Introduction to Devops",
        "Introduction to Cloud computing",
        " GIT: Version Control",
        "Jenkins – Continuous Integration"
      ]
    },

    {
      title: "Track 6",
      info: "Hands on Exercise",
      items: [
        "Color Guesser Game ",
        "Character Count App",
        "Travel app Bucket List",
        "Project Management Dashboard ",
        "E-commerce Website"
      ]
    },

    {
      title: "Track 7",
      info: "Git      ",
      items: [
        "Introduction to Git",
        "Git Repositories",
        "Track changes in your code across versions",
        "Showcase your work"
      ]
    },
    {
      title: "Track 8",
      info: "Agile Methology",
      items: [
        "Individuals and interactions over processes and tools.",
        "Working software over comprehensive documentation.",
        " Customer collaboration over contract negotiation.",
        "Responding to change over following a plan."
      ]
    },
    {
      title: "Track 9",
      info: "Tools and Language",
      items: [
        "Visual Studio Code",
        "Mongod",
        "Jenkins",
        "Gitlab",
        "Microsoft Azure"
      ]
    },
    {
      title: "Track 10",
      info: "Capstone Project",
      items: [
        "Webapp with MEAN Stack",
        "Middleware with extraction of data and displaying details"
      ]
    },
    {
      title: "Track 11",
      info: "Career Service",
      items: [
        "Career Advice",
        "Create a high-quality resume and cover letter",
        "Interview coaching and practice",
        "Job search Advice",
        "Mock interviews for both technical and non-technical topics"
      ]
    },
    {
      title: "Track 12",
      info: "Mentorship Support",
      items: [
        "Setting learning goals",
        "Review of projects and exercises",
        "Industry insights",
        "Interview tips",
        "Career and Job Search advice",
        "Tracking weekly progress"
      ]
    }
  ];
  constructor() {}

  ngOnInit() {}
}

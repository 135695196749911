import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-erp-material",
  templateUrl: "./erp-material.component.html",
  styleUrls: ["./erp-material.component.css"]
})
export class ErpMaterialComponent implements OnInit {
  erpmaterial = [
    {
      title: "Track 1",
      info: " Introduction to ERP, SAP and Methodology ",
      items: [
        "Introduction to ERP",
        "Introduction to SAP, S/4 Hana and FIORI",
        "Agile Methodology",
        "Technical Objects Overview"
      ]
    },
    {
      title: "Track 2",
      info: "Enterprise Structure Overview",
      items: [
        "ENTERPRISE STRUCTURE & Management",
        "Organization Structure",
        "MASTER DATA",
        "Pricing and Condition technique"
      ]
    },
    {
      title: "Track 3",
      info: " Purchasing Process and Data ",
      items: [
        "Purchase Process and Document",
        "Source Determination",
        "Release procedure of purchasing documents",
        "Outline Agreement"
      ]
    },
    {
      title: "Track 4",
      info: "Inventory Management",
      items: [
        "Inventory management",
        "Physical Inventory",
        "Valuation and Account Determination",
        "Invoice Verification"
      ]
    },
    {
      title: "Track 5",
      info: " Special Stocks and Procurement types",
      items: [
        "Third Party Processing",
        "Subcontracting",
        "Consignment",
        "Stock Transfer Order",
        "Integration with other Modules"
      ]
    },
    {
      title: "Track 6",
      info: "Capstone Project",
      items: [
        "Business Blueprint",
        "Implementation Process",
        "Support Process",
        "Functional Design Documents "
      ]
    },
    {
      title: "Track 7",
      info: "Career Service",
      items: [
        "Career Advice",
        "Create a high-quality resume and cover letter",
        "Interview coaching and practice",
        "Job search Advice",
        "Mock interviews for both technical and non-technical topics"
      ]
    },
    {
      title: "Track 8",
      info: "Mentorship Support",
      items: [
        "Setting learning goals",
        "Review of projects and exercises",
        "Industry insights",
        "Interview tips",
        "Career and Job Search advice",
        "Tracking weekly progress"
      ]
    }
  ];

  constructor() {}

  ngOnInit() {}
}
